import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const AmbassadorsTermsOfUsage = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
                Terms of usage for Ambassadors
            </div>
        </div>
        

        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column'}}>                
                    <div style={{color:'grey'}}>Welcome to the MyServiceCircle Ambassadors Program. By participating in this program, you agree to comply with the following Terms of Usage.</div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>1. Eligibility & Participation</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        Participation in the MSC Ambassadors Program is by invitation or approval only. MyServiceCircle.App reserves the right to approve or reject any application at its sole discretion.
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>2. Ambassador Responsibilities</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        Ambassadors are expected to:
                        <ul>
                            <li>Promote MyServiceCircle.App in a professional and ethical manner</li>
                            <li>Issue valid discount coupons within the allowed range (5%-50%)</li>
                            <li>Not engage in fraudulent activities, spam, or misrepresentation</li>
                            <li>Ensure compliance with all applicable laws and ethical standards</li>
                        </ul>
                        Failure to meet these responsibilities may result in suspension or termination from the program.
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>3. Commission & Payouts</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        Ambassadors are entitled to earn **10% commission** on the total amount generated through the successful use of their issued coupons. Commissions will be paid at scheduled intervals, subject to:
                        <ul>
                            <li>The validity of transactions</li>
                            <li>No fraudulent or unauthorized activities</li>
                            <li>Adherence to these Terms of Usage</li>
                        </ul>
                        **MyServiceCircle.App retains full discretion over all payouts and reserves the right to withhold or revoke commissions in cases of non-compliance, abuse, or violation of these terms.**
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>4. Program Modification & Termination</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        MyServiceCircle.App **reserves the right to modify, suspend, or terminate** the MSC Ambassadors Program at any time, with or without prior notice.  
                        <br></br><br></br>
                        Additionally, **MyServiceCircle.App holds absolute discretion** to revoke an Ambassador’s privileges and terminate their participation in the program, including but not limited to:
                        <ul>
                            <li>Violation of these Terms of Usage</li>
                            <li>Engagement in fraudulent or deceptive practices</li>
                            <li>Any conduct deemed harmful to the platform’s reputation</li>
                        </ul>
                        In the event of termination, **payouts may be withheld, and no legal claims or disputes shall be entertained.**  
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>5. Limitation of Liability</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        MyServiceCircle.App **shall not be liable for any indirect, incidental, or consequential damages** arising from participation in the Ambassadors Program.  
                        <br></br><br></br>
                        Furthermore, **participation in this program does not create any partnership, employment, or agency relationship** between the Ambassador and MyServiceCircle.App.
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>6. Data Usage & Privacy</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        By participating in this program, you acknowledge and agree to the **Privacy Policy** of MyServiceCircle.App. Your personal and business data will be used solely for program-related activities, including:
                        <ul>
                            <li>Tracking coupon usage and commission calculations</li>
                            <li>Processing payouts and account verification</li>
                            <li>Communication related to program updates</li>
                        </ul>
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>7. Governing Law</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        These Terms of Usage shall be governed and interpreted in accordance with the **laws of India**. Any disputes arising from or related to these terms shall be subject to the jurisdiction of the courts of **Mumbai, Maharashtra, India**.
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>8. Changes to These Terms</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        MyServiceCircle.App reserves the right to update or modify these Terms of Usage at any time. Any changes will be posted on this page, and it is your responsibility to review them periodically.
                    </div>
                </div>

                <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                    <h2>9. Contact Us</h2>
                    <div style={{color:'grey',fontSize:12}}>
                        If you have any questions regarding these Terms of Usage, please contact us at **support@myservicecircle.app**.  
                        <br></br><br></br>
                        By participating in the MSC Ambassadors Program, you acknowledge that you have read, understood, and agree to these Terms of Usage.
                    </div>
                </div>

            </div>

    </>
    )
}

export default AmbassadorsTermsOfUsage;