import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const RefundPolicy = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
            Refund Policy
            </div>
        </div>

        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column'}}>
                <h2>Effective Date: [13.03.2025]</h2>
                <div style={{color:'grey'}}>At MyServiceCircle.App, we strive to provide our users with reliable and high-quality services. However, we understand that there may be circumstances that require a refund. This Refund Policy outlines the conditions, process, and terms under which refunds will be granted, ensuring transparency and protecting the interests of all parties involved.</div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>1. Refund Eligibility</h2>
                <div style={{color:'grey',fontSize:12}}>
                Refunds are offered under specific conditions to ensure fairness. Please review the eligibility criteria:
                <ul>
                    <li>Refunds are available only if services are unavailable for a continuous period equal to or exceeding the user’s subscription eligibility period.</li>
                    <li>Refund requests must be made within 15 days from the date of service disruption.</li>
                    <li>Proof of service unavailability, including error messages or outage logs, must be provided with the refund request.</li>
                </ul>
                </div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>2. Refund Process</h2>
                <div style={{color:'grey',fontSize:12}}>
                Users can request refunds through the following methods:
                <ul>
                    <li>Online Help Desk: Submit a ticket by selecting the payment-related category in the app.</li>
                    <li>Email: Send your request to support@myservicecircle.app with detailed information and proof of purchase.</li>
                    
                </ul>
                All requests will be reviewed within 7 business days. Additional documentation may be requested if needed.
                </div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>3. Refund Method</h2>
                <div style={{color:'grey',fontSize:12}}>
                Approved refunds will be processed within 15 days and returned to the original payment method unless otherwise agreed upon. Users are responsible for ensuring their payment details are accurate.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>4. Transaction Fees</h2>
                <div style={{color:'grey',fontSize:12}}>
                A non-refundable transaction fee of 7% will be deducted from the total refund amount to cover processing costs.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>5. Non-refundable Items</h2>
                <div style={{color:'grey',fontSize:12}}>                
                <ul>
                    <li>App Purchases: All in-app purchases, including subscription fees and one-time purchases, are non-refundable.</li>
                    <li>Subscription Plan Upgrades: Refunds are not available for plan upgrades. Downgrades are not permitted after upgrading to a higher-tier plan.</li>
                    <li>Advertising Module: Ad campaign purchases are non-refundable and subject to community guideline compliance.</li>
                    
                </ul>
                
                </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>6. Redeemable Balance</h2>
                <div style={{color:'grey',fontSize:12}}> 
                In cases where a Redeemable Balance arises (e.g., when upgrading from a long-term subscription to a short-term subscription):               
                <ul>
                    <li>The Redeemable Balance will be retained in the user’s account and can only be used for future payments, including:</li>
                    <li>Renewals of the main subscription package.</li>
                    <li>Purchases or renewals of additional client packs.</li>
                    <li>No cash refunds will be issued for Redeemable Balance.</li>
                    <li>Users can view and manage their Redeemable Balance under the “My Subscriptions” section in the app/webclient.</li>
                    
                </ul>                
                </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>7. Special Conditions</h2>
                <div style={{color:'grey',fontSize:12}}> 
                              
                <ul>
                    <li>Refunds may be denied if the request fails to meet the eligibility criteria outlined in this policy.</li>
                    <li>MyServiceCircle.App reserves the right to amend this policy at any time. Changes will be posted on this page with an updated effective date.</li>
                    <li>Users are advised to review the refund policy periodically to stay informed of updates.</li>                    
                </ul>                
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>8. Limitations of Liability</h2>
                <div style={{color:'grey',fontSize:12}}> 
                MyServiceCircle.App is not liable for delays or failures in processing refunds caused by third-party payment gateways or incorrect user details. Our liability is limited to the amount paid by the user for the disputed service.
                </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>Contact Us</h2>
                <div style={{color:'grey',fontSize:12}}> 
                If you have any questions or concerns regarding this refund policy, please contact us at support@myservicecircle.app. Our support team is available to assist you with any inquiries.<br></br><br></br>By using MyServiceCircle.App, you agree to the terms of this refund policy.
                </div>
            </div>

                        
        </div>
        </>
    )
}

export default RefundPolicy;