import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const AmbassadorsPrivacyPolicy = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
                Privacy Policy for Ambassadors
            </div>
        </div>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>

        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column'}}>                
            <div style={{color:'grey'}}>Welcome to the MyServiceCircle Ambassadors Program. Your privacy is important to us. Please review our Privacy Policy to understand how we handle your personal data.</div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>1. Information We Collect</h2>
            <div style={{color:'grey',fontSize:12}}>
                As part of the MSC Ambassadors Program, we collect and store certain data, including but not limited to:
                <ul>
                    <li>Full Name</li>
                    <li>Email Address</li>
                    <li>Contact Number</li>
                    <li>Business Details (if applicable)</li>
                    <li>Referral and Commission Data</li>
                    <li>Transaction Records</li>
                </ul>
                This data is used solely to manage your participation in the program and process your commissions.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>2. How We Use Your Information</h2>
            <div style={{color:'grey',fontSize:12}}>
                We use the collected data to:
                <ul>
                    <li>Track referral coupons and commission earnings</li>
                    <li>Facilitate communication regarding the program</li>
                    <li>Process payouts based on successful referrals</li>
                    <li>Improve the MSC Ambassadors experience</li>
                </ul>
                Your data is not shared with third-party advertisers or marketing agencies.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>3. Data Security</h2>
            <div style={{color:'grey',fontSize:12}}>
                We prioritize the security of your personal data. MyServiceCircle implements appropriate technical and organizational measures to protect against unauthorized access, alteration, or disclosure of your information.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>4. Data Retention</h2>
            <div style={{color:'grey',fontSize:12}}>
                Your personal data will be retained as long as you are an active MSC Ambassador. If you choose to leave the program, your data will be removed within **30 days**, except for transaction records required for legal compliance.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>5. Payouts and Commissions</h2>
            <div style={{color:'grey',fontSize:12}}>
                Commissions are based on successful coupon redemptions. Your earnings will be transferred to your registered bank account or digital wallet as per our **Payment Terms & Conditions**.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>6. Third-Party Integrations</h2>
            <div style={{color:'grey',fontSize:12}}>
                To process payouts and track referral activity, we may integrate with third-party payment processors and analytics tools. These platforms adhere to industry-standard security protocols.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>7. Your Rights</h2>
            <div style={{color:'grey',fontSize:12}}>
                You have the right to:
                <ul>
                    <li>Access and update your personal data</li>
                    <li>Request deletion of your data (excluding transaction history)</li>
                    <li>Withdraw from the program at any time</li>
                </ul>
                To exercise these rights, contact us at **support@myservicecircle.app**.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>8. Changes to This Policy</h2>
            <div style={{color:'grey',fontSize:12}}>
                MyServiceCircle reserves the right to update this Privacy Policy at any time. Changes will be posted on this page, and it is your responsibility to review this policy periodically.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>9. Governing Law</h2>
            <div style={{color:'grey',fontSize:12}}>
                This policy is governed by the laws of India, USA & UK. Any legal actions or proceedings related to this policy shall be resolved in the courts of Mumbai, Maharashtra, India.
            </div>
        </div>
    
        <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
            <h2>10. Contact Us</h2>
            <div style={{color:'grey',fontSize:12}}>
                If you have any questions or concerns regarding this Privacy Policy, please contact us at **support@myservicecircle.app**.  
                <br></br><br></br>By participating in the MSC Ambassadors Program, you acknowledge that you have read, understood, and agree to this Privacy Policy.
            </div>
        </div>
    
    </div>
    </>
    )
}

export default AmbassadorsPrivacyPolicy;