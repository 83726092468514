import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const Company = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
                Company
            </div>
        </div>

        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column'}}>
                <h2>About Us</h2>
                <div style={{color:'grey'}}>Welcome to MyServiceCircle.App, your trusted platform for connecting clients and service providers across various industries. As a leading solution for managing on-site services, we aim to simplify the way clients interact with their service providers, offering a seamless and efficient experience.</div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                
                <div style={{color:'grey'}}>MyServiceCircle.App is designed to bridge the gap between clients and their preferred service providers, enabling easy access to services in a wide range of fields. Our platform offers a comprehensive suite of tools for managing annual maintenance contracts, service requests, and complaint tracking. With real-time updates and seamless communication, clients and service providers can efficiently manage their interactions and service history.

                <p>Our app stands out by offering:</p>

                <ul>
                    <li>Comprehensive Service Management: From booking requests to tracking service history, MyServiceCircle.App provides all the tools you need to manage your services effectively.</li>
                    <li>Real-Time Updates: Stay informed with instant notifications and updates, ensuring you’re always in the loop.</li>
                    <li>Premium Features: For those looking for more, we offer premium subscriptions with advanced features such as web access, graphical reports, and invoicing capabilities.</li>
                </ul>
            </div>
            </div>
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>Parent Company: Meritz Communications</h2>
                <div style={{color:'grey'}}>MyServiceCircle.App is proudly developed and managed by Meritz Communications, a leader in IT solutions and creative services. With expertise in mobile app development, web solutions, and advertising, Meritz Communications is committed to delivering innovative and reliable technology solutions that empower businesses.</div>
            </div>
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>Our Vision</h2>
                <div style={{color:'grey'}}>At MyServiceCircle.App, our vision is to transform the way clients and service providers interact, making service management simpler, faster, and more effective. We strive to create a platform that not only meets the needs of today’s businesses but also anticipates the challenges of tomorrow.</div>
            </div>
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>Join Us</h2>
                <div style={{color:'grey'}}>Whether you are a client looking to streamline your service management or a service provider seeking to expand your reach, MyServiceCircle.App is the perfect platform for you. Join our growing community and experience the future of service management today.</div>
            </div>

        </div>
        </>
    )
}

export default Company;