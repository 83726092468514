import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StickyHeader from './components/common/StickyHeader';
import BottomNav from './components/common/BottomNav';

import Home from './components/Home';
import Company from './components/Company';
import Contact from './components/Contact';
import Support from './components/Support';
import TermsOfUsage from './components/TermsOfUsage';
import RefundPolicy from './components/RefundPolicy';
import ShippingAndDelivery from './components/ShippingAndDelivery';
import PrivacyPolicy from './components/PrivacyPolicy';
import Disclaimer from './components/Disclaimer';
import AmbassadorsPrivacyPolicy from './components/AmbassadorsPrivacyPolicy';
import AmbassadorsTermsOfUsage from './components/AmbassadorsTermsOfUsage';
import DeepLinkRedirect from './components/DeepLinkRedirect';

function App() {
  return (
    <Router>
      <StickyHeader />
      <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="Company" element={<Company />} />
      <Route exact path="Contact" element={<Contact />} />
      <Route exact path="Support" element={<Support />} />
      <Route exact path="TermsOfUsage" element={<TermsOfUsage />} />
      <Route exact path="RefundPolicy" element={<RefundPolicy />} />
      <Route exact path="ShippingAndDelivery" element={<ShippingAndDelivery />} />
      <Route exact path="PrivacyPolicy" element={<PrivacyPolicy />} />
      <Route exact path="Disclaimer" element={<Disclaimer />} />
      <Route exact path="AmbassadorsPrivacyPolicy" element={<AmbassadorsPrivacyPolicy />} />
      <Route exact path="AmbassadorsTermsOfUsage" element={<AmbassadorsTermsOfUsage />} />
      <Route path="SP/:spid" element={<DeepLinkRedirect />} />
      </Routes>
      <BottomNav />
    </Router>
  );
}

export default App;
