import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const DeepLinkRedirect = () => {
  const { spid } = useParams();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isAndroid = /Android/i.test(userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

    //const appScheme = `mscapp://CLIENT_SP_NEW?sp_unique=${spid}`;
    const appScheme = `mscapp://SP/${spid}`;

    const androidStore = 'https://play.google.com/store/apps/details?id=com.myservicecirclemobile.meritz';
    const iosStore = 'https://apps.apple.com/app/idYOUR_IOS_APP_ID'; // Replace this

    const fallbackTimeout = setTimeout(() => {
      window.location.href = isAndroid ? androidStore : iosStore;
    }, 2000); // Fallback after 2 seconds

    // Try opening the app
    window.location.href = appScheme;

    return () => clearTimeout(fallbackTimeout);
  }, [spid]);

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h3>Redirecting to the app...</h3>
    </div>
  );
};

export default DeepLinkRedirect;
