import React, { useEffect, useState } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { Button, Select, Pane, Dialog, TextInput, Alert, toaster, Spinner} from 'evergreen-ui';
import ReCAPTCHA from "react-google-recaptcha";
import { BounceLoader, CircleLoader, ClipLoader, BeatLoader, BarLoader, FadeLoader, PulseLoader } from 'react-spinners';

const Support = () =>{

    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [formSubmitLoader, setFormSubmitLoader] = useState(false);
    const [verificationDialog, setVerificationDialog] = useState(false);
    const [uniqueId, setUniqueId] = useState(null);
    const [isVerified, setIsVerified] = useState(false);
    const [orgName, setOrgName] = useState(null);
    const [dialogLoader, setDialogLoader] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        role: "",
        message: "",
      });

      useEffect(()=>{
        if(formData.role){
          //alert(formData.role);
          setVerificationDialog(true);
        }
      },[formData.role]);

    function onChange(value) {
        setCaptchaVerified(!!value);
    }
    const handleChange = (name, value) => {
        setFormData({
          ...formData,
          [name]: value,
        });
      };
  const verifyId = async() =>{
    if(!uniqueId || uniqueId.length < 5){
      showToast('Please type a valid unique ID');
      return;
    }

    setDialogLoader(true);

    try{
      const dataString = {
        uniqueId,
        type:formData.role
      }
      const response = await fetch('https://access.myservicecircle.app/web-extra/verifyId.php',{
        method:'POST',
        headers:{
          'Content-Type' : 'application/json'
        },
        body: JSON.stringify(dataString)
      });
      const responseData = await response.json();
      if(responseData.name){
        setIsVerified(true);
        setVerificationDialog(false);        
        setOrgName(responseData.name);
      }else{
        showToast("sorry, we could not find that ID in our system");
      }
    }catch(error){
      console.log(error);
    }finally{
      setDialogLoader(false);
    }


  }


  const showToast = (value) =>{
    toaster.danger(`${value}`);
  }
  const submitSupport = async () => {
    
    if(!formData.name || formData.name.length < 4){
      showToast('Please type your full name');
      return;
    }
    if(!formData.email || formData.email.length < 8){
      showToast('Please type your email address');
      return;
    }

    if(!formData.role){
      showToast('Please select the role you are using our app as / having problem with');
      return;
    }

    if(!formData.message || formData.message < 20){
      showToast('Please type a good long message describing about your trouble');
      return;
    }
    if(!isVerified){
      showToast('You have not verified your unique id with us. Please select the role again and verify your unique id with us.');
      return;
    }
    if(!captchaVerified){
        showToast('Sounds funny, but please verify to the bot, created by a human, to prove that you are a human');
        return;
    }
    setFormSubmitLoader(true);
    try{

      const dataString = {
        role:formData.role,
        uniqueId,
        name:orgName,
        message:formData.message
      }
      const response = await fetch('https://mailers.myservicecircle.app/support/support_request.php',{
        method : 'POST',
        headers:{
          'Content-Type' : 'application/json'
        },
        body:JSON.stringify(dataString)
      });

      const responseData = await response.json();

      if(responseData.success == 1){
        setFormSubmit(true);
      }else{
        showToast("Pops! looks like there was some problem with our server. Please try again later.");
      }

    }catch(error){
      console.log(error);
    }finally{
      setFormSubmitLoader(false);
    }
  
  };

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
                Support
            </div>
        </div>
         <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:25,fontWeight:300,color:'#000 ',width:'100%',padding:'5px'}}> 
                Stuck somewhere?<br></br>
                We are always ready to help. Just tell us how.
            </div>
        </div>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>
           <div className='center-content' style={{textAlign:'left',justifyContent:'center',flexDirection:'row',alignItems:'center',boxShadow:'none',backgroundColor:'#f2f2f2'}}>
           <div className="contact-sections">
            <div className="feedback-form">
                <div className="form">
           {formSubmitLoader ?
              <PulseLoader color='#FCC100' />
              :
              <>
              {formSubmit ?
                <>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:10,color:'green',fontSize:15,fontWeight:'bold'}}>
                          Your request has been submitted successfully! You may close this page!
                      </div>
                </>
                :
                <>
                <label>
                  Name:
                  <input type="text" name="name" value={formData.name} onChange={(e) => handleChange(e.target.name, e.target.value)} required />
                </label>
                <label>
                  Email:
                  <input type="email" name="email" value={formData.email} onChange={(e) => handleChange(e.target.name, e.target.value)} required />
                </label>
                
                  {isVerified ?
                    <>
                      <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:10,color:'green',fontSize:15,fontWeight:'bold'}}>
                          ID verfied. Name : {orgName ? orgName : 'loading'}
                      </div>
                    </>
                    :
                    <>
                    <label>
                    You are a?:
                    <Select name="role" value={formData.role} style={{marginLeft:20}} onChange={(e) => handleChange("role", e.target.value)} required>
                      <option value="" style={{padding:10}}>Select</option>
                      <option value="service_provider" style={{padding:10}}>Service Provider</option>
                      <option value="service_seeker" style={{padding:10}}>Service Seeker (Client)</option>
                      <option value="service_agent" style={{padding:10}}>Service Agent</option>
                    </Select>
                  </label>
                    </>
                  }
                
                 
                  
                <label>
                  Message:
                  <textarea name="message" rows="5" maxLength={500} value={formData.message} onChange={(e) => handleChange(e.target.name, e.target.value)} required></textarea>
                </label>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                  
                </div>
                <Button appearance="primary" onClick={submitSupport} disabled={!isVerified}>
                  Submit
                </Button>
                <div style={{width:'100%',padding:10,justifyContent:'center',alignItems:'center'}}>
                <ReCAPTCHA
                    sitekey="6LecMfMqAAAAAFsDK5kKcHv_dDPrfnqgh0Shdjf6"
                    secretkey="6LecMfMqAAAAAKIF5evNuPVJLKqnF9uhUNkz4XNx"
                    onChange={onChange}
                    onExpired={() => setCaptchaVerified(false)} 
                />
                </div>
                </>
                  }
            </>
            }
            </div>
            </div>
            </div>
           </div>
        </div>
        
      <Dialog
        isShown={verificationDialog}        
        onCloseComplete={() => setVerificationDialog(false)}
        hasHeader={false}
        hasFooter={false}
      >
        <div style={{width:'100%',justifyContent:'center',alignItems:'center',paddingBottom:50}}>
          {dialogLoader ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <PulseLoader style={{ fontSize: 15, color: "#FCC100" }} color='#FCC100'/>
            </div>
          
            :
            <>
              <h5>Type your unique verification id</h5>
              <div style={{display:'flex',gap:10}}>
                <TextInput name="text-input-name" placeholder={formData.role === 'service_provider' ? 'For eg. SP-XX-XXXXXX' : formData.role === 'service_seeker' ? 'For eg. MSC-CUS-XXXXXX' : 'For Eg. MSC-SA-XXXXXXXX'} value={uniqueId} onChange={(e) => setUniqueId(e.target.value)}/>
                <Button style={{display:'flex',padding:5,backgroundColor:'#FCC100',fontSize:15,color:'#fff',borderColor:'#FCC100'}} onClick={verifyId}>Verify</Button>
              </div>
              </>
          }
          
        </div>

      </Dialog>

      
    
        </>
    )
}

export default Support;