import React, { useEffect, useState } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import {Button, TextInput, toaster} from 'evergreen-ui';
import ReCAPTCHA from "react-google-recaptcha";
const Contact = () =>{

    const [formData, setFormData] = useState({
        name: "",
        email: "",        
        message: "",
      });

      const [feedbackSent, setFeedbackSent] = useState(false);
      const [captchaVerified, setCaptchaVerified] = useState(false);
      const handleChange = (name, value) => {
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      function onChange(value) {
        setCaptchaVerified(!!value);
      }
      

      const submitForm = async () =>{
       

        if(!formData.name || formData.length < 5){
            toaster.danger("Please type your full name");
            return;
        }
        if(!formData.email || formData.email < 10){
            toaster.danger("Please type a valid email address");
            return;
        }
        if(!formData.email || formData.message < 10){
            toaster.danger("A good valid comment is very important");
            return;
        }

        if (!captchaVerified) {
            toaster.danger("Please complete the CAPTCHA verification.");
            return;
        }

        try{
            const response = await fetch('https://mailers.myservicecircle.app/support/contact_send.php',{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify({formData})
            });

            const responseData = await response.json();

            if(responseData.success == 1){
                toaster.success("Your feedback has been received.");
                setFeedbackSent(true);
                
            }else{
                toaster.danger("Something went wrong");
            }
        }catch(error){
            console.log(error)
        }
      }
    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
                Contact us
            </div>
        </div>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',flexDirection:'column'}}>
            <iframe
            src="https://www.google.com/maps/embed/v1/view?key=AIzaSyBxaj3BLFeQAXIGxUL9FtSOqELilzvyjM8&center=18.956216671969596,72.83950648423401&zoom=22&maptype=roadmap"
            width="100%"
            height="400"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            title="Meritz Communications Location"
            >

            </iframe>
        </div>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>
            <div className='center-content' style={{textAlign:'left',justifyContent:'center',flexDirection:'row',alignItems:'center',boxShadow:'none',backgroundColor:'#f2f2f2'}}>
            <div className="form-container">
            <div className="address-section">
                <div className="address-title">Postal Address</div>
                <div className="address-detail">Meritz Communications (Parent Firm)</div>
                <div className="address-detail">509A, Sterling Chambers, Poona Street, Danabunder, Mumbai - 400009</div>
                <div className="address-detail">+91 22 23715220</div>
                <div className="address-detail">support@myservicecircle.app</div>
                <div className="address-hours">Mon-Fri 9am - 5pm</div>
            </div>

            {!feedbackSent ? 
            
            
            <div className="form-section">
                
                <div className="form-blocks">
                    <label className="form-label">Name</label>
                    <TextInput placeholder='Full name' required width="100%" name='name' value={formData.name} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                </div>

                <div className="form-blocks">
                    <label className="form-label">Email</label>
                    <TextInput type='email' placeholder='Email address'  name='email' value={formData.email} required width="100%" onChange={(e) => handleChange(e.target.name, e.target.value)} />
                </div>

                <div className="form-blocks">
                    <label className="form-label">Message</label>
                    <textarea placeholder='Your message'  name='message' value={formData.message} required rows={5} maxLength={255} onChange={(e) => handleChange(e.target.name, e.target.value)} className="message-textarea" />
                </div>

                <Button appearance="primary" type="submit" marginTop={16} style={{backgroundColor:'#6f248f',borderColor:'#6f248f'}} onClick={submitForm}>Submit</Button>
                <ReCAPTCHA
                    sitekey="6LecMfMqAAAAAFsDK5kKcHv_dDPrfnqgh0Shdjf6"
                    secretkey="6LecMfMqAAAAAKIF5evNuPVJLKqnF9uhUNkz4XNx"
                    onChange={onChange}
                    onExpired={() => setCaptchaVerified(false)} 
                />
            </div>
            :
            <>
             <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>
             <div className='center-content' style={{textAlign:'left',justifyContent:'center',flexDirection:'row',alignItems:'center',boxShadow:'none',backgroundColor:'#f2f2f2'}}>
                Thank you for your feedback! We shall get back to you soon.
            </div>
             </div>
            </>
        }
            </div>
            </div>
            </div>
        </>
    )
}


export default Contact;