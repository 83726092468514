import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const Disclaimer = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
                Disclaimer            
            </div>
        </div>

        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>
                     
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>1. General Information</h2>
                <div style={{color:'grey',fontSize:12}}>
                MyServiceCircle.App is a platform designed to connect clients with service providers for various on-site services. While we strive to facilitate seamless interactions and transactions, MyServiceCircle.App does not directly provide these services. All services are offered by third-party providers.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>2. No Endorsement</h2>
                <div style={{color:'grey',fontSize:12}}>
                The inclusion of any service provider or client on MyServiceCircle.App does not imply endorsement or recommendation. We do not guarantee the quality, suitability, safety, or ability of any service providers. Any disputes or issues regarding the services provided must be resolved between the client and the service provider.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>3. Limitation of Liability</h2>
                <div style={{color:'grey',fontSize:12}}>
                MyServiceCircle.App, its affiliates, and its employees are not liable for any direct, indirect, incidental, or consequential damages arising from the use of our platform. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.
                </div>
            </div>
         
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>4. Confidentiality and Intellectual Property</h2>
                <div style={{color:'grey',fontSize:12}}>
                While we take measures to protect your data, MyServiceCircle.App cannot guarantee the absolute security of your information. By using our app, you acknowledge and agree that any information you share may be subject to unauthorized access. MyServiceCircle.App and its affiliates are not responsible for any breach of confidentiality.<br></br><br></br>All content, trademarks, and intellectual property associated with MyServiceCircle.App are owned by their respective owners. Unauthorized use of any intellectual property is strictly prohibited.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>5. User Responsibilities</h2>
                <div style={{color:'grey',fontSize:12}}>
                Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users must provide accurate, current, and complete information when registering and using MyServiceCircle.App. Any misuse of the platform, including but not limited to fraud, harassment, or spamming, will result in immediate termination of the user's account.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>6. Third-Party Links</h2>
                <div style={{color:'grey',fontSize:12}}>
                MyServiceCircle.App may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>7. Changes to the Disclaimer</h2>
                <div style={{color:'grey',fontSize:12}}>
                We reserve the right to modify this disclaimer at any time. Any changes will be posted on this page, and it is your responsibility to review this disclaimer periodically.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>8. Governing Law</h2>
                <div style={{color:'grey',fontSize:12}}>
                This disclaimer is governed by the laws of India. Any legal actions or proceedings related to or arising out of this disclaimer shall be resolved in the courts of Mumbai, Maharashtra, India.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>9. Contact Us</h2>
                <div style={{color:'grey',fontSize:12}}>
                If you have any questions or concerns about this disclaimer, please contact us at support@myservicecircle.app.<br></br>By using MyServiceCircle.App, you acknowledge that you have read, understood, and agree to be bound by this disclaimer.
                </div>
            </div>

                        
        </div>
        </>
    )
}

export default Disclaimer;