import React, { useState, useRef, useEffect } from 'react';
import { MdMenu, MdClose  } from "react-icons/md";
import logo from '../../images/logo.png'; // or wherever your logo is
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom

function StickyHeader() {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const [menuOpen, setMenuOpen] = useState(false);
  const headerRef = useRef(null);
  const navigate = useNavigate();
  // Determine if header should stick when scrolling
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true, offset: elHeight });
    } else {
      setSticky({ isSticky: false, offset: 0 });
    }
  };

  // Attach / detach scroll event listener
  useEffect(() => {
    const headerRect = headerRef.current.getBoundingClientRect();
    const onScroll = () => {
      handleScroll(headerRect.top, headerRect.height);
    };

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div style={{ marginTop: sticky.offset }}>
      
      <div
        id="sticky-header"
        className={`navbar${sticky.isSticky ? ' sticky' : ''}`}
        ref={headerRef}
      >
        <div className="navbar__inner">
          
          <div className="navbar__logo">
            <img src={logo} alt="My Logo" />
          </div>

          
          <div className={`navbar__links${menuOpen ? ' open' : ''}`}>
            <a href="#" onClick={() => setMenuOpen(!menuOpen)}><Link to="/">Home</Link></a>
            <a href="#" onClick={() => {setMenuOpen(!menuOpen);navigate('/Company')}}>Company</a>
            <a href="#" onClick={() => {setMenuOpen(!menuOpen);navigate('/Contact')}}>Contact</a>
            <a href="#" onClick={() => {setMenuOpen(!menuOpen);navigate('/Support')}}>Support</a>
            <a href="https://webclient.myservicecircle.app" onClick={() => setMenuOpen(!menuOpen)}>Webclient</a>
            
            {menuOpen &&
            <div style={{top:0,right:0,color:'#fff',position:'absolute',zIndex:10}} onClick={()=>setMenuOpen(false)}>
            <MdClose size={24} />
            </div>
            }
          </div>

          
          <div
            className="navbar__toggle"
            onClick={() => setMenuOpen(!menuOpen)}
            style={{color:'#fff'}}
          >
            <MdMenu size={24} />
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default StickyHeader;
