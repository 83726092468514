import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const Home = ()=>{
    useEffect(() => {
        const handleScroll = () => {
          const blocks = document.querySelectorAll('.box');
          blocks.forEach((block, index) => {
            const blockPosition = block.getBoundingClientRect().top;
            const screenPosition = window.innerHeight / 1.1;
            if (blockPosition < screenPosition) {
              setTimeout(() => {
                block.classList.add('active');
              }, index * 300); // Add delay between each block
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    

    return( 
        <>
        
        <Carousel
            autoPlay={true}
            infiniteLoop={true}
            emulateTouch={true}
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            
          >
            <div className="carousel-slide" style={{backgroundColor:'#FCC100'}}>
              <img src={centerbig} className="carousel-image"/>
              <div className="carousel-text">
                <div style={{fontSize:25,color:'#fff'}}>One App... All connected</div>
                <div style={{fontSize:15,color:'#fff'}}>Simplify your service management and elevate your experience. A single intuitive app seamlessly connecting service providers, clients, and field agents—centralize tasks, streamline communication, track progress in real-time, and effortlessly manage every role through one unified platform.</div>                
              </div>
            </div>
            <div className="carousel-slide" style={{backgroundColor:'#7ab02a'}}>
              <img src={mapImage} className="carousel-image" />
              <div className="carousel-text">
                <div style={{fontSize:25,color:'#fff'}}>Track Your Agent, Live!</div>
                <div style={{fontSize:15,color:'#fff'}}>No more waiting or guessing. Get real-time alerts when your agent is on the way and track their exact location live—just like your favorite delivery apps!</div>                
                <div style={{fontSize:15,color:'#fff'}}>Available for the Clients and Service providers too</div>                
              </div>
            </div>
            <div className="carousel-slide" style={{backgroundColor:'#00A8DC'}}>
            <img src={notify} className="carousel-image" />
            <div className="carousel-text">
              <div style={{fontSize:25,color:'#fff'}}>Instant Alerts, Zero Hassle</div>
              <div style={{fontSize:15,color:'#fff'}}>Stay instantly updated with real-time notifications. Never miss a service request, appointment reminder, or important update again.</div>
            </div>
          </div>

          <div className="carousel-slide" style={{backgroundColor:'#992388'}}>
            <img src={calling} className="carousel-image" />
            <div className="carousel-text">
              <div style={{fontSize:25,color:'#fff'}}>Effortless Communication</div>
              <div style={{fontSize:15,color:'#fff'}}>Seamlessly chat and make calls directly within the app, connecting providers, clients, and agents for smoother service experiences.</div>
            </div>
          </div>

          <div className="carousel-slide" style={{backgroundColor:'#EF884D'}}>
            <img src={webclient} className="carousel-image" />
            <div className="carousel-text">
              <div style={{fontSize:25,color:'#fff'}}>Detailed Performance Insights</div>
              <div style={{fontSize:15,color:'#fff'}}>Gain powerful insights into agent performance, client satisfaction, and service efficiency through intuitive analytics dashboards.</div>
            </div>
          </div>
          
          </Carousel>

       
        <hr></hr>
        <h1 style={{textAlign:'center'}}>For Service Providers</h1>
       <div className='container'>
            <div className='box' style={{textAlign:'right',alignItems:'flex-end'}}>
                <img src={sp} style={{marginTop:-45}}/>
            </div>
            <div className='box' style={{textAlign:'left',alignItems:'flex-start'}}>
            <ul class="features-list">
            <li class="feature-item">Deliver a class of service to all your existing clients</li>
            <li class="feature-item">Get rid of nagging client phone calls</li>
            <li class="feature-item">Real-time complaint and service request management</li>
            <li class="feature-item">Streamlined communication with integrated chat & calls</li>
            <li class="feature-item">Detailed analytics on agent and service performance</li>
            <li class="feature-item">Instant notifications keeping you always informed</li>
            </ul>
            </div>
       </div>
       <hr></hr>
       <h1 style={{textAlign:'center'}}>For Clients</h1>
       <div className='container'>
            <div className='box' style={{textAlign:'right',alignItems:'flex-end'}}>
            <ul class="features-list">
            <li class="feature-item">Connect with all your existing service providers through one app</li>
            <li class="feature-item">Effortlessly track your service requests and complaints</li>
            <li class="feature-item">Real-time updates on the status of your services</li>
            <li class="feature-item">Convenient and direct communication through chat & calls</li>
            <li class="feature-item">Leave detailed feedback and ratings for service providers</li>
            <li class="feature-item">Receive instant notifications and reminders</li>
        </ul>
            </div>
            <div className='box' style={{textAlign:'left',alignItems:'flex-start'}}>
                <img src={client} />
            </div>
       </div>
       <hr></hr>
       <h1 style={{textAlign:'center'}}>For the field staff</h1>
       <div className='container'>
            <div className='box' style={{textAlign:'right',alignItems:'flex-end'}}>
                <img src={agent} />
            </div>
            <div className='box' style={{textAlign:'left',alignItems:'flex-start'}}>
            <ul class="features-list">
                <li class="feature-item">Get immediate alerts for new tasks and service assignments.</li>
                <li class="feature-item">Real-Time Service Updates & Tracking</li>
                <li class="feature-item">Effortlessly manage and track your assigned services.</li>
                <li class="feature-item">Communicate directly with clients and providers in real-time.</li>
                <li class="feature-item">Access your detailed performance and feedback reports.</li>
                <li class="feature-item">Simplified management for quicker issue resolutions.</li>
                
            </ul>
            </div>
           
       </div>
       </>
    )
}

export default Home;