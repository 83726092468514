import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const PrivacyPolicy = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
            Privacy Policy
            </div>
        </div>

        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column'}}> 
                <h2>Effective Date: [13.03.2025]</h2>               
                <div style={{color:'grey'}}>MyServiceCircle.App ("we," "us," or "our") is committed to protecting the privacy of our users ("you," "your," or "users"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our app, MyServiceCircle.App. Please read this policy carefully to understand our practices regarding your information.</div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>1. Information We Collect</h2>
                <div style={{color:'grey',fontSize:12}}>
                <strong>Personal Information</strong>: We may collect personal information such as your name, email address, phone number, and payment information when you register or use our app.<br></br><strong>Service Information</strong>: Information related to the services you provide or request through our app, including service history, preferences, and feedback.<br></br><strong>Device Information</strong>: Information about your device, including IP address, browser type, operating system, and mobile network information.<br></br><strong>Usage Information</strong>: Information about how you use our app, including your interactions with other users and the services you request or provide.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>2. How We Use Your Information</h2>
                <div style={{color:'grey',fontSize:12}}>
                We use the information we collect for various purposes, including:
                <ul>
                    <li>To facilitate and improve our services</li>
                    <li>To process transactions and send you related information</li>
                    <li>To provide customer support</li>
                    <li>To send you technical notices, updates, security alerts, and support messages</li>
                    <li>To monitor and analyze usage and trends to improve user experience</li>
                    <li>To detect, investigate, and prevent fraudulent transactions and other illegal activities</li>
                    <li>To comply with legal obligations</li>
                </ul>
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>3. Sharing Your Information</h2>
                <div style={{color:'grey',fontSize:12}}>
                We do not sell or rent your personal information to third parties. We may share your information with:
                <ul>
                    <li>Service Providers: Third-party vendors, consultants, and service providers who need access to your information to perform work on our behalf.</li>
                    <li>Legal Obligations: When required by law, regulation, legal process, or governmental request.</li>
                    <li>Business Transfers: In connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</li>
                </ul>
                </div>
            </div>
         
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>4. Data Security</h2>
                <div style={{color:'grey',fontSize:12}}>
                We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure. We cannot guarantee absolute security.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>5. Your Privacy Rights</h2>
                <div style={{color:'grey',fontSize:12}}>
                You may update, correct, or delete your account information at any time by logging into your account. You may also contact us to request access to, correct, or delete any personal information that you have provided to us.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>6. Third-Party Services</h2>
                <div style={{color:'grey',fontSize:12}}>
                Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. Please review their privacy policies before providing them with any personal information.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>7. Children's Privacy</h2>
                <div style={{color:'grey',fontSize:12}}>
                Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>8. Changes to This Privacy Policy</h2>
                <div style={{color:'grey',fontSize:12}}>
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>9. Contact Us</h2>
                <div style={{color:'grey',fontSize:12}}>
                If you have any questions or concerns about this Privacy Policy, please contact us at support@myservicecircle.app.<br></br><br></br>By using MyServiceCircle.App, you agree to the collection and use of information in accordance with this Privacy Policy.
                </div>
            </div>

                        
        </div>
        </>
    )
}

export default PrivacyPolicy;