import React, { useState, useRef, useEffect } from 'react';
import { MdMenu, MdClose  } from "react-icons/md";
import logo from '../../images/logo.png'; // or wherever your logo is
import { Link, useNavigate } from 'react-router-dom'; // Import Link from react-router-dom

const BottomNav = () =>{

    const navigate = useNavigate();
    return(
        <div className='bottomBar'>
            <div className='bottomItems' style={{cursor:'pointer'}} onClick={()=>navigate('/TermsOfUsage')}>Terms of usage</div>
            <div className='bottomItems' style={{cursor:'pointer'}} onClick={()=>navigate('/RefundPolicy')}>Refund Policy</div>
            <div className='bottomItems' style={{cursor:'pointer'}} onClick={()=>navigate('/ShippingAndDelivery')}>Shipping</div>
            <div className='bottomItems' style={{cursor:'pointer'}} onClick={()=>navigate('/PrivacyPolicy')}>Privacy Policy</div>
            <div className='bottomItems' style={{cursor:'pointer'}} onClick={()=>navigate('/Disclaimer')}>Disclaimer</div>
        </div>
    )
}

export default BottomNav;