import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const TermsOfUsage = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
            Terms of Usage
            </div>
        </div>

        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column'}}>
                <h2>Effective Date: [13.03.2025]</h2>
                <div style={{color:'grey'}}>Welcome to MyServiceCircle.App. By accessing or using our platform, you agree to be bound by the following terms and conditions ("Terms of Usage"). If you do not agree with these terms, please do not use our services.</div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>1. User Account and Registration</h2>
                <div style={{color:'grey',fontSize:12}}>
                1.1 Account Creation: Users must create an account to access our services. Registration requires OAuth authentication and a secure password, encrypted with a two-layer encryption process.<br></br><br></br>1.2 Service Providers: Must provide location coordinates, tax registration numbers (for invoicing purposes), and complete contact information, including address, email, and phone numbers. Accurate and up-to-date information is required to maintain visibility and functionality within the app.<br></br><br></br>1.3 Clients: Required to provide location information for each product they add, including coordinates and product details with images, to facilitate service interactions.<br></br><br></br>1.4 Service Agents: Must share real-time location data with the app to enable client tracking. Profile pictures are mandatory for service agents and optional for other users.<br></br><br></br>1.5 Eligibility: While no strict age restrictions are enforced, users must comply with applicable local laws regarding age limitations for online services.<br></br>
                </div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>2. User Responsibilities</h2>
                <div style={{color:'grey',fontSize:12}}>
                2.1 Account Security: Users are responsible for maintaining the confidentiality of their account credentials and are advised to use in-app communication features for privacy protection.<br></br><br></br>2.2 Direct Transactions: Users acknowledge that all financial transactions between clients and service providers occur outside of MyServiceCircle.App’s systems. As such, the platform does not participate in or guarantee any payments or agreements made between parties.<br></br><br></br>2.3 No Liability: MyServiceCircle.App disclaims all liability for the actions, conduct, or services provided by any user, whether a client, service provider, or service agent. Users are encouraged to perform due diligence and verify credentials before engaging with other users.<br></br><br></br>
                </div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>3. Prohibited Activities</h2>
                <div style={{color:'grey',fontSize:12}}>
                Users agree not to engage in or facilitate the following activities:<br></br>
                <ul>
                    <li>Any illegal or unauthorized activities, including but not limited to drug trafficking, prostitution, or fraudulent schemes.<br></br></li>
                    <li>Harassment, abuse, or threats against other users or third parties.<br></br></li>
                    <li>Misuse of the platform, including spamming, hacking, or distribution of malicious software.<br></br></li>
                </ul>
                </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>4. Intellectual Property</h2>
                <div style={{color:'grey',fontSize:12}}>
                4.1 Ownership: The app's design, concept, logo, color theme, and brand identity are owned by MyServiceCircle.App and protected by applicable intellectual property laws.<br></br><br></br>

                4.2 User Content: Users may share screenshots for personal use but may not reproduce, distribute, or create derivative works from the app’s content without prior authorization.
               </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>5. Service Availability and Modifications</h2>
                <div style={{color:'grey',fontSize:12}}>
                5.1 Right to Modify: MyServiceCircle.App reserves the right to modify, suspend, or discontinue any aspect of the app at any time. Users will be notified of significant changes through our website and, where feasible, via email notifications.<br></br><br></br>
                5.2 Refunds: In the event of permanent service discontinuation, eligible refunds will be processed according to our refund policy.
               </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>6. Disclaimers and Limitation of Liability</h2>
                <div style={{color:'grey',fontSize:12}}>
                6.1 Third-Party Services: MyServiceCircle.App does not endorse or assume responsibility for the quality, reliability, or accuracy of services provided by third-party providers on our platform.<br></br><br></br>6.2 No Warranties: The app is provided "as-is" and "as-available" without any warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.<br></br><br></br>6.3 Limitation of Liability: Under no circumstances shall MyServiceCircle.App, its affiliates, or their respective directors, employees, or agents be liable for any indirect, incidental, consequential, or punitive damages, including lost profits or data, arising from your use of the app.
               </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>7. Dispute Resolution</h2>
                <div style={{color:'grey',fontSize:12}}>
                7.1 Service Provider and Client Disputes: MyServiceCircle.App is not a party to transactions or agreements between clients and service providers. All disputes arising from such interactions must be resolved independently by the involved parties.<br></br><br></br>7.2 Arbitration: Disputes between users and MyServiceCircle.App shall be resolved through binding arbitration, in accordance with the rules of the Indian Arbitration and Conciliation Act. The arbitration shall be conducted in Mumbai, India, and the decision of the arbitrator shall be final and binding.
               </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>8. Termination</h2>
                <div style={{color:'grey',fontSize:12}}>
                8.1 Suspension and Termination: MyServiceCircle.App reserves the right to suspend or terminate a user's account at its sole discretion, especially in cases of multiple complaints or breaches of these Terms of Usage. Complaints must be submitted with supporting evidence via email or the help desk.
               </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>9. Governing Law</h2>
                <div style={{color:'grey',fontSize:12}}>
                These terms and conditions shall be governed by and construed in accordance with the laws of India. Users agree to submit to the exclusive jurisdiction of the courts located in Mumbai, Maharashtra, India.
               </div>
            </div>

                        
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>Contact Information</h2>
                <div style={{color:'grey',fontSize:12}}>
                For questions or concerns about these Terms of Usage, please contact us at support@myservicecircle.app.<br></br><br></br>By using MyServiceCircle.App, you agree to comply with these Terms of Usage. We reserve the right to update these terms, and continued use of the app constitutes acceptance of any changes.
               </div>
            </div>

                        
        </div>
        </>
    )
}

export default TermsOfUsage;