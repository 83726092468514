import React, { useEffect } from 'react';
import './common/common.css';
import { FaWrench, FaUsers, FaHandHoldingHeart, FaCheck } from 'react-icons/fa';
import logo from '../images/logo.png';
import sp from '../images/sp.png';
import client from '../images/client.png';
import agent from '../images/agent.png';
import centerbig from '../images/centerbig.png';
import mapImage from '../images/map.png';
import notify from '../images/notify.png';
import calling from '../images/calling.png';
import webclient from '../images/webclient.png';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const ShippingAndDelivery = () =>{

    return(
        <>
        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',}}>
            <div style={{fontSize:35,fontWeight:100,color:'#fff',backgroundColor:'#993366',width:'100%',padding:'5px'}}> 
            Shipping and Delivery
            </div>
        </div>

        <div style={{display:'flex',width:'100%',alignItems:'center',textAlign:'center',justifyContent:'center',marginTop:20,flexDirection:'column'}}>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column'}}>                
                <div style={{color:'grey'}}>Welcome to MyServiceCircle.App. Please review our Shipping and Delivery policy for digital goods and services provided through our platform.</div>
            </div>

            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>1. Digital Goods and Services</h2>
                <div style={{color:'grey',fontSize:12}}>
                MyServiceCircle.App facilitates the purchase and delivery of digital goods and services such as subscriptions, access to premium features, and other digital offerings. As these are non-tangible items, there is no physical shipment involved.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>2. Delivery Timeline</h2>
                <div style={{color:'grey',fontSize:12}}>
                Upon successful payment, access to digital goods or services will be granted immediately or within a maximum of 24 hours. You will receive an email confirmation with details of your purchase and activation.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>3. Access Issues</h2>
                <div style={{color:'grey',fontSize:12}}>
                If you experience any issues accessing your purchased services or digital goods, please contact us immediately at support@myservicecircle.app. We will resolve your issue within 2 business days.
                </div>
            </div>
         
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>4. Failed Transactions</h2>
                <div style={{color:'grey',fontSize:12}}>
                In case of failed transactions or unsuccessful payment processing, no access will be granted to the digital goods or services. Please retry the transaction or contact your payment provider for assistance. For unresolved payment issues, contact us at support@myservicecircle.app.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>5. Refund and Cancellation</h2>
                <div style={{color:'grey',fontSize:12}}>
                As our services are digital in nature, refunds and cancellations are governed by our Refund Policy. Please review it carefully before making a purchase.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>6. No Physical Delivery</h2>
                <div style={{color:'grey',fontSize:12}}>
                MyServiceCircle.App only deals in digital services and subscriptions. There are no shipping or physical delivery charges associated with any of our offerings.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>7. Changes to this Policy</h2>
                <div style={{color:'grey',fontSize:12}}>
                MyServiceCircle.App reserves the right to modify this Shipping and Delivery policy at any time. Changes will be posted on this page, and it is your responsibility to review this policy periodically.
                </div>
            </div>
            
            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>8. Governing Law</h2>
                <div style={{color:'grey',fontSize:12}}>
                This policy is governed by the laws of India. Any legal actions or proceedings related to this policy shall be resolved in the courts of Mumbai, Maharashtra, India.
                </div>
            </div>

            <div className='center-content' style={{textAlign:'left',justifyContent:'flex-start',flexDirection:'column',marginTop:10}}>
                <h2>9. Contact Us</h2>
                <div style={{color:'grey',fontSize:12}}>
                If you have any questions or concerns about our Shipping and Delivery policy, please contact us at support@myservicecircle.app.<br></br><br></br>By using MyServiceCircle.App, you acknowledge that you have read, understood, and agree to this Shipping and Delivery policy.
                </div>
            </div>

            
           
                        
        </div>
        </>
    )
}

export default ShippingAndDelivery;